import { FC, memo } from "react";

import dynamic from "next/dynamic";

import { useTranslations } from "next-intl";

import ProductBoxButtonsMainPage from "../ProductBoxButtons/ProductBoxButtonsMainPage";
import ProductPrices from "../ProductPrices";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { CurrencyType, ProductViewType } from "types/QuickCartTypes";

import ProductTypeIconButton, {
  getProductTypeText,
} from "components/ProductTypeIconButton";
import ShortRatings from "components/Ratings/ShortRatings";

import { colorText100, colorText700, colorText800 } from "theme/colors";

const SystemCategoryIcon = dynamic(
  () => import("components/CategoryIcons/SystemCategoryIcon"),
);

type ProductBoxMainPageProps = {
  dataBox: ProductViewType;
  data: { currency: CurrencyType; language?: string; siteId?: string };
};
const ProductBoxMainPage: FC<ProductBoxMainPageProps> = ({ dataBox, data }) => {
  const promotion = dataBox.promotion;
  const rate = dataBox?.rate;
  const t = useTranslations("common");
  const productTypeText = getProductTypeText(dataBox.productType, t);

  const defaultSx = { height: "100%", border: "solid 2px" };

  return (
    <Box id="fcx-product-box" p={7} className={"fcx-round-5"} sx={defaultSx}>
      <Grid className={"new-test-container"}>
        {dataBox.newProductBadge && (
          <CardMedia
            component="img"
            alt="nowe badanie ikona"
            width="60"
            src="/new-test.png"
            title="nowe badanie ikona"
            className={"new-test-img"}
          />
        )}
      </Grid>
      <Grid
        container
        className={"below-new-test"}
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pb: 3 }}
          >
            <Stack direction="row" spacing={2} sx={{ pb: 3 }}>
              <SystemCategoryIcon
                iconName={dataBox.icon}
                iconColor={dataBox.categoryView.iconColor}
                backgroundColor={dataBox.categoryView.iconBackgroundColor}
                title={dataBox.categoryView.name}
              />
              {dataBox.productTypeFamily === "MEDICAL_EXAMINATIONS" && (
                <Tooltip title={productTypeText} role="img">
                  <Box
                    className={"fcx-product-type-box fcx-icon-box-mini-bg"}
                    sx={{
                      backgroundColor: colorText100,
                    }}
                  >
                    <ProductTypeIconButton productType={dataBox.productType} />
                  </Box>
                </Tooltip>
              )}
            </Stack>
            {promotion && (
              <Box className={"circlePromotion"}>
                <Typography
                  variant="body2"
                  component={"span"}
                  className={"textPromotion"}
                  lineHeight={"64%"}
                >
                  {promotion?.discountValue}%
                  <br />
                  <Typography
                    variant="body2"
                    component={"span"}
                    sx={{ fontSize: "24px" }}
                  >
                    {t("product_cheaper")}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Stack>

          {dataBox.url && dataBox.name && (
            <Grid item xs={12} pt={2} pb={2}>
              <Typography variant={"body1"} sx={{ color: colorText700, pb: 2 }}>
                {productTypeText}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom={false}
                className={"fcx-mb-5"}
              >
                <Link
                  sx={{ color: colorText800 }}
                  href={dataBox.url}
                  title={dataBox.name}
                >
                  {dataBox.name}
                </Link>
              </Typography>
              {dataBox.categoryView.name && (
                <Grid item xs={12}>
                  <Link
                    href={dataBox.categoryView.url}
                    title={dataBox.categoryView.name}
                    variant="body1"
                    className={"fcx-cursor-pointer f-normal"}
                    sx={{ color: colorText700 }}
                  >
                    {dataBox.categoryView.name}
                  </Link>
                </Grid>
              )}
              {dataBox.additionalText && (
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ color: colorText700, fontWeight: 100 }}
                >
                  {dataBox.additionalText}
                </Typography>
              )}
              {rate && (
                <Grid item xs={12}>
                  <ShortRatings rate={rate} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid item xs={12} py={2}>
            <ProductPrices
              product={dataBox}
              currency={data.currency.code}
              isConsultationsPage={false}
            />
            <ProductBoxButtonsMainPage
              productId={dataBox.id}
              productName={dataBox.name}
              productUrl={dataBox.url}
              productCategoryName={dataBox.categoryView?.name}
              productRetailPrice={dataBox.retailPrice}
              productTemporarilyUnavailable={dataBox.temporarilyUnavailable}
              siteId={data.siteId}
              language={data.language}
            />
          </Grid>
          <Grid item xs={12}>
            {dataBox.temporarilyUnavailable && (
              <Typography sx={{ color: colorText800 }}>
                {t("temporarily-unavailable")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ProductBoxMainPage);
