"use client";
import { FC } from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import theme from "theme";

import { colorOrange } from "theme/colors";

interface SliderButton {
  colorButton?: string;
  colorButtonText?: string;
  button: string;
  buttonUrl: string;
  classNames: string;
}

const SliderButton: FC<SliderButton> = ({
  colorButton,
  colorButtonText,
  button,
  buttonUrl,
  classNames,
}) => {
  const c =
    colorButton && colorButton.trim() !== "" ? colorButton : colorOrange;
  const myTheme = createTheme(theme, {
    palette: {
      secondary: {
        main: c,
        dark: alpha(c, 0.75),
        contrastText: colorButtonText,
      },
    },
  });

  return (
    <ThemeProvider theme={myTheme}>
      <Button
        color={"secondary"}
        endIcon={<ArrowOutwardIcon />}
        variant="contained"
        disableElevation={true}
        className={classNames}
        href={buttonUrl}
        sx={{ color: colorButtonText }}
      >
        {button}
      </Button>
    </ThemeProvider>
  );
};

export default SliderButton;
