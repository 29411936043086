import { FC } from "react";

import Image from "next/image";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SliderButton from "components/MainCarousel/SlidderButton";
import SlideNavigation from "components/MainCarousel/SlideNavigation";

import { cleanUpImageUrl } from "utils/ImagesUrl";

import { colorOrange, colorText50 } from "theme/colors";

type SlideClassicProps = {
  buttonUrl: string;
  image: string;
  imageMobile: string;
  imageAlt?: string;
  header: string;
  button: string;
  itemNumber: number;
  elementsCount: number;
  content?: string;
  colorButtonText?: string;
  colorButton?: string;
  colorText?: string;
};

const SlideClassic: FC<SlideClassicProps> = ({
  buttonUrl,
  button,
  image,
  imageMobile,
  imageAlt,
  header,
  itemNumber,
  elementsCount,
  content,
  colorButtonText,
  colorButton,
  colorText,
}) => {
  const isContentNotEmpty = content && content !== "";

  return (
    <Box
      onClick={() =>
        buttonUrl
          ? (document.location.href = buttonUrl)
          : console.error("no-slide-url")
      }
    >
      <Box
        height="610px"
        style={{
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Image
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${cleanUpImageUrl(
              image,
            )}`}
            fill
            priority={itemNumber === 1}
            alt={imageAlt ? imageAlt : ""}
            // unoptimized
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Image
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${cleanUpImageUrl(
              imageMobile && imageMobile !== "" ? imageMobile : image,
            )}`}
            fill
            priority={itemNumber === 1}
            alt={imageAlt ? imageAlt : ""}
            // unoptimized
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Container
          maxWidth="xl"
          className={"fullHeight"}
          sx={{ position: "relative" }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            textAlign={"left"}
            px={"40px"}
            pt={"40px"}
            pb={"80px"}
            className={"fullHeight"}
          >
            {isContentNotEmpty && (
              <Typography
                variant="h6"
                component="div"
                color={colorOrange}
                sx={{ mb: 5 }}
                fontWeight={600}
              >
                {content}
              </Typography>
            )}
            {header && (
              <>
                {/* desktop view start */}
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="h1"
                      component="h2"
                      color={
                        colorText && colorText.trim() !== ""
                          ? colorText
                          : colorText50
                      }
                      sx={{
                        fontSize: "4rem",
                        fontWeight: 700,
                        lineHeight: 1.1,
                        mb: 5,
                      }}
                    >
                      {header}
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent={
                      button && buttonUrl ? "space-between" : "flex-end"
                    }
                    alignItems="flex-end"
                    spacing={2}
                  >
                    {button && buttonUrl && (
                      <SliderButton
                        button={button}
                        colorButton={colorButton}
                        colorButtonText={colorButtonText}
                        buttonUrl={buttonUrl}
                        classNames="fcx-pt-5 fcx-pb-10 fcx-px-40 buttonSlider"
                      />
                    )}
                    <SlideNavigation
                      itemNumber={itemNumber}
                      elementsCount={elementsCount}
                    />
                  </Stack>
                </Box>
                {/* desktop view end */}
                {/* mobile view start */}
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <Typography
                    variant="h1"
                    component="h2"
                    className={"fcx-mb-40 rem25"}
                    color={
                      colorText && colorText.trim() !== ""
                        ? colorText
                        : colorText50
                    }
                  >
                    {header}
                  </Typography>
                  {button && buttonUrl && (
                    <SliderButton
                      button={button}
                      colorButton={colorButton}
                      colorButtonText={colorButtonText}
                      buttonUrl={buttonUrl}
                      classNames="fcx-pt-5 fcx-pb-10 fcx-px-20 buttonSliderMobile"
                    />
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "2rem",
                      alignContent: "center",
                      width: "100%",
                      marginLeft: "-40px",
                    }}
                  >
                    <SlideNavigation
                      itemNumber={itemNumber}
                      elementsCount={elementsCount}
                    />
                  </Box>
                </Box>
                {/* mobile view end */}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default SlideClassic;
