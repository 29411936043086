"use client";
import { FC, memo } from "react";

import { useTranslations } from "next-intl";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import theme from "theme";

import { addToCard } from "utils/AddToCard";

import {
  colorOrange,
  colorText100,
  colorText200,
  colorText800,
} from "theme/colors";
import { CartIcon } from "theme/SvgIcons";

type ProductBoxButtonMainPageProps = {
  siteId?: string;
  language?: string;
  productId: number;
  productUrl?: string;
  productTemporarilyUnavailable: boolean;
  productRetailPrice: string;
  productCategoryName?: string;
  productName: string;
};

const ProductBoxButtonMainPageProps: FC<ProductBoxButtonMainPageProps> = ({
  siteId,
  language,
  productId,
  productUrl,
  productTemporarilyUnavailable,
  productRetailPrice,
  productCategoryName,
  productName,
}) => {
  const myTheme = createTheme(theme, {
    palette: {
      primary: {
        main: colorOrange,
        dark: alpha(colorOrange, 0.75),
      },
      secondary: {
        main: colorText100,
        dark: colorText200,
        contrastText: colorText800,
      },
    },
  });

  const t = useTranslations("common");

  return (
    <ThemeProvider theme={myTheme}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box width={"100%"}>
          {productUrl && (
            <Button
              color="secondary"
              href={productUrl}
              variant="contained"
              fullWidth
              sx={{ minHeight: 52, boxShadow: "none" }}
            >
              {t("product-details")}
            </Button>
          )}
        </Box>
        <Button
          variant="contained"
          disabled={productTemporarilyUnavailable}
          color="primary"
          sx={{ minWidth: 38, minHeight: 52, boxShadow: "none" }}
          aria-label="Do koszyka"
          onClick={() =>
            addToCard(
              productId,
              siteId,
              language,
              productRetailPrice,
              productCategoryName,
              productName,
              null,
            )
          }
        >
          <CartIcon />
        </Button>
      </Stack>
    </ThemeProvider>
  );
};

export default memo(ProductBoxButtonMainPageProps);
